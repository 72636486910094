import React from 'react';
import {BiAlignLeft} from 'react-icons/bi';
import {MdOutlineManageAccounts} from 'react-icons/md';

const routesConfig = [
  {
    id: 'app',
    title: 'Sample',
    messageId: 'sidebar.sample',
    type: 'group',
    children: [
      // {
      //   id: 'purchase-request',
      //   title: 'Page 1',
      //   messageId: 'sidebar.sample.purchase-request',
      //   icon: <BiAlignLeft />,
      //   path: '',
      //   type: 'collapse',

      //   children: [
      //     {
      //       id: 'all-purchase-request',
      //       permission: 'applications',
      //       title: 'Page 11',
      //       messageId: 'sidebar.sample.all-purchase-request',
      //       type: 'item',
      //       icon: <BiAlignLeft />,
      //       path: '/sample/table?pageId=all-purchase-request',
      //     },
      //     {
      //       id: 'new-purchase-request',
      //       permission: 'applications',
      //       title: 'Page 11',
      //       messageId: 'sidebar.sample.new-purchase-request',
      //       type: 'item',
      //       icon: <BiAlignLeft />,
      //       path: '/sample/table?pageId=new-purchase-request&status=new',
      //     },
      //     {
      //       id: 'active-purchase-request',
      //       permission: 'applications',
      //       title: 'Page 12',
      //       messageId: 'sidebar.sample.active-purchase-request',
      //       type: 'item',
      //       icon: <BiAlignLeft />,
      //       path: '/sample/table?pageId=active-purchase-request&status=active',
      //     },
      //     {
      //       id: 'duplicate-purchase-request',
      //       permission: 'applications',
      //       title: 'Page 13',
      //       messageId: 'sidebar.sample.duplicate-purchase-request',
      //       type: 'item',
      //       icon: <BiAlignLeft />,
      //       path: '/sample/table?pageId=duplicate-purchase-request&status=duplicate',
      //     },
      //   ],
      // },
      {
        id: 'user',
        title: 'Page 2',
        messageId: 'sidebar.sample.user',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?pageId=user',
        permissions:["superadmin"]
      },
      {
        id: 'course',
        title: 'Page 2',
        messageId: 'sidebar.sample.course',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?pageId=course',
      },
      {
        id: 'course-section',
        title: 'Page 2',
        messageId: 'sidebar.sample.course-section',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?pageId=course-section',
      },
      {
        id: 'video',
        title: 'Page 2',
        messageId: 'sidebar.sample.video',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?pageId=video',
      },
      // {
      //   id: 'purchase',
      //   title: 'Page 2',
      //   messageId: 'sidebar.sample.purchase',
      //   type: 'item',
      //   icon: <BiAlignLeft />,
      //   path: '/sample/table?pageId=purchase',
      // },
      // {
      //   id: 'plan',
      //   title: 'Page 2',
      //   messageId: 'sidebar.sample.plan',
      //   type: 'item',
      //   icon: <BiAlignLeft />,
      //   path: '/sample/table?pageId=plan',
      // },
    ],
  },
  {
    id: 'extra-pages',
    title: 'Extra Pages',
    messageId: 'sidebar.pages.extraPages',
    path: 'extra-pages',
    type: 'group',
    children: [
      {
        id: 'account',
        title: 'Account',
        messageId: 'sidebar.pages.extraPages.account',
        icon: <MdOutlineManageAccounts />,
        path: '/extra-pages/user-profile',
      },
    ],
  },
];
export default routesConfig;
