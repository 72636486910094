import routesConfig from 'pages/routeConfig';
import {useJWTAuth} from '@crema/services/auth/jwt-auth/JWTAuthProvider';

const getRouteChildren = (route) => {
  const {permissions} = route;
  const {user} = useJWTAuth();
  if (permissions) {
    return permissions.includes(user.userData.role);
  }

  return true;
};

const getFilteredRoute = (route) => {
  const children = route.children?.filter(getRouteChildren);
  if (!children.length) {
    return false;
  }

  return {
    ...route,
    children,
  };
};

export const getFilteredRoutes = () => {
  return routesConfig.map(getFilteredRoute).filter((el) => el);
};
