import React from 'react';

export const samplePagesConfigs = [
  {
    path: '/sample/table',
    component: React.lazy(() => import('./Table')),
  },
  {
    path: ['/sample/course', '/sample/course/:id'],
    component: React.lazy(() => import('./Course')),
  }, {
    path: [  '/sample/user/:id'],
    component: React.lazy(() => import('./User')),
  },
  {
    path: ['/sample/course-section', '/sample/course-section/:id'],
    component: React.lazy(() => import('./CourseSection')),
  },
  {
    path: ['/sample/plan', '/sample/plan/:id'],
    component: React.lazy(() => import('./Plan')),
  },
  {
    path: ['/sample/video', '/sample/video/:id'],
    component: React.lazy(() => import('./Video')),
  },{
    path: ['/sample/video-list' ],
    component: React.lazy(() => import('./VideoList')),
  },
];
